export default function IconSolidNew() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      id="Add-Circle--Streamline-Micro"
      height="100%"
      width="100%"
    >
      <desc>{'Add Circle Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        fill="currentColor"
        d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm3.6 9.200000000000001h-2.4000000000000004v2.4000000000000004a1.2000000000000002 1.2000000000000002 0 0 1 -2.4000000000000004 0v-2.4000000000000004h-2.4000000000000004a1.2000000000000002 1.2000000000000002 0 0 1 0 -2.4000000000000004h2.4000000000000004v-2.4000000000000004a1.2000000000000002 1.2000000000000002 0 0 1 2.4000000000000004 0v2.4000000000000004h2.4000000000000004a1.2000000000000002 1.2000000000000002 0 0 1 0 2.4000000000000004Z"
        strokeWidth={1}
      />
    </svg>
  );
}
